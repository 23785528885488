import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDateSeconds } from '@/support/String';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report as ReportModel, Media, attachmentTypes, Report } from '@/models/Report';
import { Damage } from '@/models/Damage';
import { Panel } from '@/components/damage-panel/DamagePanel';
import { Rpc } from '@/models/Rpc';
import { SubmissionDamage } from '@/models/SubmissionDamage';
import { Submission, statusColorMap, SubmissionStatus, statusLabelMap } from '@/models/Submission';

@Component<MSR>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDateSeconds(date);
    },
  },
})
export default class MSR extends Vue {
  public $pageTitle = 'MSR';

  protected applicantSubmission: Submission = new Submission();

  protected damages: Damage[] = [];

  protected submissionDamages: SubmissionDamage[] = [];

  protected isLoading = true;

  protected hasFailed = false;

  protected hasPanelsOpen = false;

  protected hasDamagePanelsOpen = false;

  protected checkAllDamages = false;

  protected isDisabledSelectedDamage = false;

  protected showAssignImagesDialog = false;

  protected generalRemarkField = false;

  protected generalInstruction = '';

  protected showAdditionalSubmissionDialog = false;

  protected isCreatingAdditionalRequest = false;

  protected pictures: Media[] = [];

  protected panels: Panel[] | null = [];

  protected damagepanels: Panel[] | null = [];

  protected submissionDamagePhotos: SubmissionPhotos = {};

  protected selected: string[] = [];

  protected selectedDamage: string | null = null;

  protected submissionPhotos: string[] = [];

  protected selectedType = '';

  protected statusColorMap: SubmissionStatus = statusColorMap;

  protected statusLabelMap: SubmissionStatus = statusLabelMap;

  protected openSubmissionDamageRemarkFields: string[] = [];

  public mounted() {
    this.initialize();
  }

  protected initBreadcrumb() {
    if (! this.applicantSubmission.report) {
      return;
    }
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.applicantSubmission.report.case_number, path: `/reports/${this.applicantSubmission.report.uuid}` },
          { name: 'Mijn schaderegelen' },
        ],
      });
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getApplicantSubmission();
    await this.getDamages();
    await this.getReportMedia();
    this.setAdditionRemarks();
    this.openAll();
    this.openAllDamage();

    this.isLoading = false;
    this.setAllMediaActive();
    this.initBreadcrumb();
  }

  protected async getApplicantSubmission() {
    await this.applicantSubmission
      .include(['report', 'media', 'answers', 'submission_damages', 'applicant', 'data'])
      .request('get', `/applicant-submissions/${this.$route.params.id}`, {}, false)
      .then((response: AxiosResponse) => {
        this.applicantSubmission = response.data as Submission;
        this.submissionDamages = (response.data?.submission_damages as SubmissionDamage[]) || [];
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportMedia(): Promise<void> {
    if (! this?.applicantSubmission?.report?.uuid) { return; }

    const response = await new Report({ uuid: this.applicantSubmission.report.uuid }).listMedia({
      type: 'pictures',
    })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      this.pictures = response.data;
    }
  }

  protected async getDamages() {
    if (! this.applicantSubmission.report) {
      return;
    }

    await new ReportModel()
      .include(['applicant', 'damages'])
      .find(this.applicantSubmission.report.uuid)
      .then((report: ReportModel) => {
        this.damages = [];
        this.damages = (report.damages as Damage[]);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected setAdditionRemarks() {
    if (! this.applicantSubmission) { return; }

    if (this.applicantSubmission.additional_request && this.applicantSubmission.additional_request.length) {
      this.generalRemarkField = true;
    }

    this.submissionDamages.forEach((submissionDamage: SubmissionDamage) => {
      if (submissionDamage.additional_request && submissionDamage.additional_request.length) {
        this.toggleRemarkField(submissionDamage);
      }
    });
  }

  protected setAllMediaActive() {
    this.submissionDamages.forEach((submissionDamage: SubmissionDamage) => {
      if (! submissionDamage.id) {
        return;
      }

      this.submissionDamagePhotos[submissionDamage.id] = submissionDamage.media?.map((media:Media) => media.uuid) as string[];
    });
  }

  protected isSelectedMedia(damageId: string[], mediaId: string): boolean {
    if (! damageId) {
      return false;
    }

    return damageId.some((id: string) => mediaId === id);
  }

  protected async updateImagesType() {
    if (! this.applicantSubmission || ! this.applicantSubmission.report) {
      return;
    }

    const payload: {[key: string]: any} = {
      signature: 'msr:add-report-media',
      body: {
        report: this.applicantSubmission.report.uuid,
        applicant_submission: this.applicantSubmission.id,
        media: this.submissionPhotos,
        type: this.selectedType,
      },
    };

    this.isLoading = true;
    await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.hasFailed = true;
      });

    if (! this.hasFailed) {
      this.$store.dispatch('openDialog', this.dialogOptionsSuccess);
      this.selected = [];
      this.submissionPhotos = [];
      this.selectedDamage = null;
      this.initialize();
    }

    this.isLoading = false;
  }

  protected async addToDamage() {
    const payload: {[key: string]: any} = {
      signature: 'msr:merge-damages',
      body: {
        applicant_submission_damages: this.selected[0],
        damage: this.selectedDamage,
        media: this.submissionDamagePhotos[this.selected[0]],
      },
    };

    this.isLoading = true;
    await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.hasFailed = true;
      });

    if (! this.hasFailed) {
      this.$store.dispatch('openDialog', this.dialogOptionsSuccess);
      this.selected = [];
      this.selectedDamage = null;
      this.initialize();
    }

    this.isLoading = false;
  }

  protected async createAdditionalRequest() {
    this.isCreatingAdditionalRequest = true;
    const payload: {[key: string]: any} = {
      signature: 'msr:additional-request',
      body: {
        applicant_submission: this.applicantSubmission.id,
        additional_request: this.applicantSubmission.additional_request ? this.applicantSubmission.additional_request : null,
        damages: this.additionalSubmissionDamages
          .map((additionalSubmissionDamage: SubmissionDamage) => ({ id: additionalSubmissionDamage.id, additional_request: additionalSubmissionDamage.additional_request })),
        notify_applicant: true,
      },
    };

    console.log('payload', payload);

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$router.push(`/reports/${this.applicantSubmission?.report?.uuid}/mijn-schaderegelen`);
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected async createNewDamage() {
    const selectedSubmissionDamages: SubmissionDamage[] = [];
    this.selected.forEach((selected: string) => {
      const foundSubmissionDamage = this.submissionDamages.find((submission: SubmissionDamage) => submission.id === selected);

      if (foundSubmissionDamage) {
        selectedSubmissionDamages.push(foundSubmissionDamage);
      }
    });

    const applicantSubmissionDamages = selectedSubmissionDamages.map((submission: SubmissionDamage) => ({
      id: submission.id,
      media: this.submissionDamagePhotos[submission.id as string],
    }));

    if (! this.applicantSubmission.report) {
      return;
    }

    const payload: {[key: string]: any} = {
      signature: 'msr:create-damages',
      body: {
        report: this.applicantSubmission.report.uuid,
        applicant_submission: this.applicantSubmission.id,
        applicant_submission_damages: applicantSubmissionDamages,
      },
    };

    this.isLoading = true;
    await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.hasFailed = true;
      });

    if (! this.hasFailed) {
      this.$store.dispatch('openDialog', this.dialogOptionsSuccess);
      this.selected = [];
      this.initialize();
    }

    this.isLoading = false;
  }

  protected requestAdditionalSubmission() {
    if (! this.hasSubmissionDamageInstructions) { return; }
    this.showAdditionalSubmissionDialog = true;
  }

  protected checkAll() {
    if (! this.checkAllDamages) {
      this.selected = [];
      return;
    }

    this.selected = this.submissionDamages.map((submissionDamage: SubmissionDamage) => submissionDamage.id) as string[];
  }

  protected openAll() {
    this.panels = [...Array(this.submissionDamages.length).keys()].map((_) => [true]);
    this.hasPanelsOpen = true;
  }

  protected closeAll() {
    this.panels = [];
    this.hasPanelsOpen = false;
  }

  protected openAllDamage() {
    this.damagepanels = [...Array(this.damages.length).keys()].map((_) => [true]);
    this.hasDamagePanelsOpen = true;
  }

  protected closeAllDamage() {
    this.damagepanels = [];
    this.hasDamagePanelsOpen = false;
  }

  protected toggleGeneralRemarkField() {
    this.generalRemarkField = ! this.generalRemarkField;

    if (! this.generalRemarkField) {
      this.generalInstruction = '';
    }
  }

  protected toggleRemarkField(submissionDamage: SubmissionDamage): void {
    if (! submissionDamage) { return; }
    if (this.openSubmissionDamageRemarkFields.includes(submissionDamage.id)) {
      this.openSubmissionDamageRemarkFields = this.openSubmissionDamageRemarkFields
        .filter((currentId: string) => (submissionDamage as SubmissionDamage).id !== currentId);

      console.log('before clear');
      submissionDamage.additional_request = '';
      console.log('after clear', submissionDamage);
    } else {
      this.openSubmissionDamageRemarkFields.push(submissionDamage.id);
    }
  }

  protected showRemarkFied(submissionDamageId: string): boolean {
    return this.openSubmissionDamageRemarkFields.includes(submissionDamageId);
  }

  // Getters
  protected get showAddToDamage() {
    return ! this.$store.state.isServiceOrganization && this.selected.length === 1 && this.selectedDamage;
  }

  protected get showCreateNewDamage() {
    return ! this.$store.state.isServiceOrganization && this.selected.length >= 1 && ! this.selectedDamage;
  }

  protected get hasSubmissionDamageInstructions(): boolean {
    return !! (this.submissionDamages.some((submissionDamage: SubmissionDamage) => submissionDamage.additional_request?.length) || this.generalInstruction.length);
  }

  protected get additionalSubmissionDamages(): SubmissionDamage[] {
    return this.submissionDamages.filter((submissionDamage: SubmissionDamage) => submissionDamage.additional_request?.length);
  }

  protected get dialogOptionsSuccess(): Options {
    return {
      title: 'Bevestiging',
      text: 'Het aanmaken van schades is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get attachmentTypes() {
    return attachmentTypes;
  }

  protected get isAdditionalRequest() {
    return !! (this.applicantSubmission && this.applicantSubmission.type && this.applicantSubmission.type === 'additional_request');
  }

  // Watchers
  @Watch('selected')
  protected selectedChange() {
    if (this.selected.length > 1) {
      this.selectedDamage = null;
      this.isDisabledSelectedDamage = true;
    } else {
      this.isDisabledSelectedDamage = false;
    }
  }
}

interface Mask {
  [key: string]: string;
}

interface SubmissionPhotos {
  [key: string]: string[];
}
