var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msr-container" },
    [
      [
        _c(
          "v-container",
          { attrs: { fluid: "", "grid-list-lg": "" } },
          [
            _c(
              "v-layout",
              { staticClass: "mb-5", attrs: { wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                          _c("h2", { staticClass: "elementSubTitle" }, [
                            _vm._v(
                              " Zelf-opname door bewoner " +
                                _vm._s(
                                  _vm.isAdditionalRequest
                                    ? "(aanvullende uitvraag)"
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "elementPanel elementPanel__sub" },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    row: "",
                                    "align-baseline": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _vm.isLoading
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center",
                                              minHeight: "300px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isLoading
                                    ? [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "infoPanelColumn flex-ungrow px-0"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [
                                                _vm._v(" Dossiernummer: "),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.applicantSubmission
                                                          .report.case_number
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mx-3 infoPanelColumn__divider"
                                                  },
                                                  [_vm._v("|")]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "infoPanelColumn flex-ungrow px-0"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [
                                                _vm._v(" Toegangscode: "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold headline ml-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.applicantSubmission
                                                          .applicant.code
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mx-3 infoPanelColumn__divider"
                                                  },
                                                  [_vm._v("|")]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "infoPanelColumn flex-ungrow px-0"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "infoPanelColumn__fadedText"
                                              },
                                              [
                                                _vm._v(" Status: "),
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      "ml-1 statusPill statusBackgroundColor--" +
                                                      _vm.statusColorMap[
                                                        _vm.applicantSubmission
                                                          .status
                                                      ]
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.statusLabelMap[
                                                            _vm
                                                              .applicantSubmission
                                                              .status
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs6: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                      _c("h2", { staticClass: "elementSubTitle" }, [
                        _vm._v("Door bewoner opgenomen schades")
                      ])
                    ]),
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel__sub" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", row: "" } },
                            [
                              _vm.isLoading
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center",
                                          minHeight: "300px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isLoading
                                ? [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "msr-bottom-border",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "", wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "" } },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle mb-0"
                                                  },
                                                  [_vm._v("Overzichtfoto's")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs6: "",
                                                  "text-xs-right": ""
                                                }
                                              },
                                              [
                                                !_vm.$store.state
                                                  .isServiceOrganization &&
                                                _vm.submissionPhotos &&
                                                _vm.submissionPhotos.length
                                                  ? _c(
                                                      "MiButton",
                                                      {
                                                        staticClass: "mx-1",
                                                        attrs: {
                                                          color: "primary",
                                                          small: "true"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showAssignImagesDialog = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Foto's toevoegen aan "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _vm._l(
                                          _vm.applicantSubmission.media,
                                          function(media) {
                                            return _c(
                                              "div",
                                              {
                                                key: media.uuid,
                                                staticClass:
                                                  "mediaContainer mediaContainer--small list-group-item",
                                                class: {
                                                  active: _vm.isSelectedMedia(
                                                    _vm.submissionPhotos,
                                                    media.uuid
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mediaContainer__actions"
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: media.original,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "action"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "remove_red_eye"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "action action--select"
                                                      },
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass:
                                                            "action__checkbox",
                                                          attrs: {
                                                            value: media.uuid,
                                                            color: "secondary",
                                                            "off-icon": "",
                                                            "on-icon":
                                                              "check_circle",
                                                            "hide-details": ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.submissionPhotos,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.submissionPhotos = $$v
                                                            },
                                                            expression:
                                                              "submissionPhotos"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c("img", {
                                                  attrs: { src: media.thumb }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mediaContainer__selected"
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      staticClass:
                                                        "action__checkbox",
                                                      attrs: {
                                                        value: media.uuid,
                                                        color: "secondary",
                                                        "off-icon": "",
                                                        "on-icon":
                                                          "check_circle",
                                                        readonly: "",
                                                        "hide-details": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.submissionPhotos,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.submissionPhotos = $$v
                                                        },
                                                        expression:
                                                          "submissionPhotos"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        !_vm.applicantSubmission.media
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "fadedText" },
                                                [_vm._v("Geen media")]
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm.applicantSubmission.status ===
                                      "submitted" &&
                                    _vm.applicantSubmission.type !==
                                      "additional_request"
                                      ? _c("v-flex", { attrs: { xs12: "" } }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-center justify-start flex-wrap msr-add-remark-container",
                                              on: {
                                                click:
                                                  _vm.toggleGeneralRemarkField
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "msr-add-remark-icon d-flex flex-ungrow",
                                                  class: {
                                                    active:
                                                      _vm.generalRemarkField
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.generalRemarkField
                                                          ? "mdi-minus-circle"
                                                          : "mdi-plus-circle"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "msr-add-remark-description",
                                                  class: {
                                                    active:
                                                      _vm.generalRemarkField
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.generalRemarkField
                                                          ? "Geen aanvullende gegeven nodig"
                                                          : "Aanvullende gegevens uitvragen voor dit rapport"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.generalRemarkField &&
                                          _vm.applicantSubmission.type !==
                                            "additional_request"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "msr-add-remark-container"
                                                },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      label:
                                                        "Instructie voor de aanvrager"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.applicantSubmission
                                                          .additional_request,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.applicantSubmission,
                                                          "additional_request",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "applicantSubmission.additional_request"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm.applicantSubmission.type ===
                                      "additional_request" &&
                                    _vm.applicantSubmission.additional_request
                                      ? _c("v-flex", { attrs: { xs12: "" } }, [
                                          _c(
                                            "p",
                                            { staticClass: "mb-1 fadedText" },
                                            [_vm._v("Aanvullende uitvraag:")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-center justify-start flex-wrap"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "d-flex flex-ungrow mr-2 comment-question-icon"
                                                },
                                                [_vm._v("mdi-comment-question")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "italicText" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.applicantSubmission
                                                        .additional_request
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "mt-3 mb-1 fadedText"
                                            },
                                            [_vm._v("Reactie aanvrager:")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-center justify-start flex-wrap msr-add-remark-container"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "d-flex flex-ungrow mr-2 comment-question-icon"
                                                },
                                                [_vm._v("mdi-comment")]
                                              ),
                                              _vm.applicantSubmission &&
                                              _vm.applicantSubmission.data &&
                                              _vm.applicantSubmission.data
                                                .answers
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .applicantSubmission
                                                            .data.answers
                                                            .AdditionalRequestRemark
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "mt-3 msr-bottom-border",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "elementSubTitle mb-0"
                                          },
                                          [_vm._v("Schades's")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: { xs12: "", "text-xs-right": "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "" } },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "mt-1 pt-0",
                                                  attrs: {
                                                    "hide-details": "",
                                                    label: "Selecteer alle",
                                                    color: "primary"
                                                  },
                                                  on: { change: _vm.checkAll },
                                                  model: {
                                                    value: _vm.checkAllDamages,
                                                    callback: function($$v) {
                                                      _vm.checkAllDamages = $$v
                                                    },
                                                    expression:
                                                      "checkAllDamages"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "handlepanel",
                                                attrs: { xs6: "" }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _vm.hasPanelsOpen
                                                      ? _c("v-flex", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "panel"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      _vm.closeAll
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " sluit alle "
                                                                  ),
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "keyboard_arrow_up"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    !_vm.hasPanelsOpen
                                                      ? _c("v-flex", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "panel"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      _vm.openAll
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " open alle "
                                                                  ),
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "keyboard_arrow_down"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.submissionDamages, function(
                                      submissionDamage,
                                      index
                                    ) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: index,
                                          staticClass: "panel-container",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "align-top": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs1: "", shrink: "" }
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      value:
                                                        submissionDamage.id,
                                                      color: "secondary",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value: _vm.selected,
                                                      callback: function($$v) {
                                                        _vm.selected = $$v
                                                      },
                                                      expression: "selected"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs11: "", grow: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-expansion-panel",
                                                    {
                                                      staticClass: "panel",
                                                      attrs: { expand: "" },
                                                      model: {
                                                        value:
                                                          _vm.panels[index],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.panels,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "panels[index]"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-expansion-panel-content",
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "header"
                                                              },
                                                              slot: "header"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  submissionDamage.location ===
                                                                  "inside"
                                                                    ? _c(
                                                                        "v-flex",
                                                                        [
                                                                          _vm._v(
                                                                            " BINNEN - " +
                                                                              _vm._s(
                                                                                submissionDamage
                                                                                  .answers[
                                                                                  "InsideRoomNumber"
                                                                                ]
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                submissionDamage
                                                                                  .answers[
                                                                                  "InsideLocation"
                                                                                ]
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-flex",
                                                                        [
                                                                          _vm._v(
                                                                            " BUITEN - " +
                                                                              _vm._s(
                                                                                submissionDamage
                                                                                  .answers[
                                                                                  "OutsideLocation"
                                                                                ]
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "panel__inner"
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      [
                                                                        submissionDamage.answers
                                                                          ? _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs12:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Beschrijving"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fadedText block"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          submissionDamage
                                                                                            .answers[
                                                                                            "OutsideDescription"
                                                                                          ]
                                                                                        ) +
                                                                                        " " +
                                                                                        _vm._s(
                                                                                          submissionDamage
                                                                                            .answers[
                                                                                            "InsideDescription"
                                                                                          ]
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._l(
                                                                      submissionDamage.media,
                                                                      function(
                                                                        media
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              media.uuid,
                                                                            staticClass:
                                                                              "mediaContainer mediaContainer--small list-group-item",
                                                                            class: {
                                                                              active: _vm.isSelectedMedia(
                                                                                _vm
                                                                                  .submissionDamagePhotos[
                                                                                  submissionDamage
                                                                                    .id
                                                                                ],
                                                                                media.uuid
                                                                              )
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mediaContainer__actions"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a",
                                                                                  {
                                                                                    attrs: {
                                                                                      href:
                                                                                        media.original,
                                                                                      target:
                                                                                        "_blank"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "action"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "remove_red_eye"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs: {
                                                                                  src:
                                                                                    media.thumb
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    _vm
                                                                      .applicantSubmission
                                                                      .status ===
                                                                      "submitted" &&
                                                                    _vm
                                                                      .applicantSubmission
                                                                      .type !==
                                                                      "additional_request"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex flex-row align-center justify-start flex-wrap msr-add-remark-container mt-2",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.toggleRemarkField(
                                                                                  submissionDamage
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "msr-add-remark-icon d-flex flex-ungrow",
                                                                                class: {
                                                                                  active: _vm.showRemarkFied(
                                                                                    submissionDamage.id
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.showRemarkFied(
                                                                                        submissionDamage.id
                                                                                      )
                                                                                        ? "mdi-minus-circle"
                                                                                        : "mdi-plus-circle"
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "msr-add-remark-description",
                                                                                class: {
                                                                                  active: _vm.showRemarkFied(
                                                                                    submissionDamage.id
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.showRemarkFied(
                                                                                        submissionDamage.id
                                                                                      )
                                                                                        ? "Geen aanvullende gegeven nodig"
                                                                                        : "Aanvullende gegevens uitvragen voor deze schade"
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.showRemarkFied(
                                                                      submissionDamage.id
                                                                    ) &&
                                                                    _vm
                                                                      .applicantSubmission
                                                                      .type !==
                                                                      "additional_request"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "msr-add-remark-container"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-textarea",
                                                                              {
                                                                                attrs: {
                                                                                  label:
                                                                                    "Instructie voor de aanvrager"
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    submissionDamage.additional_request,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      submissionDamage,
                                                                                      "additional_request",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "submissionDamage.additional_request"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mt-3 mb-1 fadedText"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Aanvullende uitvraag:"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm
                                                                  .applicantSubmission
                                                                  .type ===
                                                                  "additional_request" &&
                                                                submissionDamage.additional_request
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-row align-center justify-start flex-wrap msr-add-remark-container"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex flex-ungrow mr-2 comment-question-icon"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-comment-question "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "italicText"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                submissionDamage.additional_request
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                submissionDamage &&
                                                                submissionDamage.answers &&
                                                                submissionDamage
                                                                  .answers
                                                                  .AdditionalRequestRemark
                                                                  ? [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "mt-3 mb-1 fadedText"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Reactie aanvrager:"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex flex-row align-center justify-start flex-wrap msr-add-remark-container"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex flex-ungrow mr-2 comment-question-icon"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-comment"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  submissionDamage
                                                                                    .answers
                                                                                    .AdditionalRequestRemark
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ]
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs6: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                      _c("h2", { staticClass: "elementSubTitle" }, [
                        _vm._v("Schades in schaderapport van deskundige")
                      ])
                    ]),
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel__sub" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", row: "" } },
                            [
                              _vm.isLoading
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center",
                                          minHeight: "300px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isLoading
                                ? [
                                    !_vm.pictures.length
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "panel-container mb-3",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                "Je hebt nog geen overzichtfoto's opgenomen in het schaderapport"
                                              )
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "panel-container msr-bottom-border mb-3",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "elementSubTitle mb-0"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Overzichtfoto's"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      _vm._l(_vm.pictures, function(picture) {
                                        return _c(
                                          "div",
                                          {
                                            key: picture.uuid,
                                            staticClass:
                                              "mediaContainer mediaContainer--small list-group-item"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mediaContainer__actions"
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: picture.original,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "action" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "remove_red_eye"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c("img", {
                                              attrs: { src: picture.thumb }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    !_vm.damages.length
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass: "panel-container",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                "Je hebt nog geen schades opgenomen in het schaderapport"
                                              )
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "panel-container msr-bottom-border",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs6: "" } },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "elementSubTitle mb-0"
                                                      },
                                                      [_vm._v("Schades's")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass: "handlepanel",
                                                    attrs: { xs6: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { wrap: "" } },
                                                      [
                                                        _vm.hasDamagePanelsOpen
                                                          ? _c("v-flex", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "panel"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          _vm.closeAllDamage
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " sluit alle "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "keyboard_arrow_up"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        !_vm.hasDamagePanelsOpen
                                                          ? _c("v-flex", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "panel"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          _vm.openAllDamage
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " open alle "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "keyboard_arrow_down"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                    _vm._l(_vm.damages, function(
                                      damage,
                                      index
                                    ) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: index,
                                          staticClass: "panel-container",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "align-top": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", grow: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-expansion-panel",
                                                    {
                                                      staticClass: "panel",
                                                      attrs: { expand: "" },
                                                      model: {
                                                        value:
                                                          _vm.damagepanels[
                                                            index
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.damagepanels,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "damagepanels[index]"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-expansion-panel-content",
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "header"
                                                              },
                                                              slot: "header"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "concatenateString"
                                                                    )(
                                                                      damage.name,
                                                                      30
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "panel__inner"
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "item__list",
                                                                        attrs: {
                                                                          "px-2":
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "label",
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-layout",
                                                                              {
                                                                                attrs: {
                                                                                  wrap:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs7:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Schade info"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs5:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Antwoord"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "list__item",
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-layout",
                                                                              {
                                                                                attrs: {
                                                                                  wrap:
                                                                                    "",
                                                                                  "align-center":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs7:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Toelichting"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs: {
                                                                                      xs5:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "text"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          "pa-2":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          _vm._l(
                                                                            damage.media,
                                                                            function(
                                                                              media,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key:
                                                                                    "media-" +
                                                                                    index,
                                                                                  staticClass:
                                                                                    "mediaContainerWithDescription mediaContainerWithDescription--small"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mediaContainer mediaContainer--small"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mediaContainer__actions"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "a",
                                                                                            {
                                                                                              attrs: {
                                                                                                href:
                                                                                                  media.original,
                                                                                                target:
                                                                                                  "_blank"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "action"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "remove_red_eye"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs: {
                                                                                            src:
                                                                                              media.thumb
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mediaContainer__name"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          media.description
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.applicantSubmission.report
          ? _c("ApprovalBar", {
              attrs: {
                backUrl:
                  "/reports/" +
                  _vm.applicantSubmission.report.uuid +
                  "/mijn-schaderegelen"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _vm.showAddToDamage
                              ? _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      color: "secondary",
                                      small: "true",
                                      icon: "add"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.addToDamage($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Toevoegen aan schade ")]
                                )
                              : _vm._e(),
                            _vm.applicantSubmission.status === "submitted" &&
                            _vm.applicantSubmission.type !==
                              "additional_request"
                              ? _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: !_vm.hasSubmissionDamageInstructions,
                                      color: "secondary",
                                      small: "true",
                                      icon: "question_answer"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.requestAdditionalSubmission(
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Aanvullende gegevens uitvragen ")]
                                )
                              : _vm._e(),
                            _vm.showCreateNewDamage
                              ? _c(
                                  "MiButton",
                                  {
                                    staticClass: "ml-3",
                                    attrs: {
                                      disabled: _vm.isLoading,
                                      color: "primary",
                                      small: "true",
                                      icon: "add"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.createNewDamage($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Maak nieuwe schade ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1120014143
              )
            })
          : _vm._e()
      ],
      _vm.showAssignImagesDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showAssignImagesDialog,
                callback: function($$v) {
                  _vm.showAssignImagesDialog = $$v
                },
                expression: "showAssignImagesDialog"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.showAssignImagesDialog = false
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Foto's toevoegen aan")
                  ]),
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.attachmentTypes,
                                    "item-value": "value",
                                    "item-text": "label",
                                    label: "Type bijlage",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.selectedType,
                                    callback: function($$v) {
                                      _vm.selectedType = $$v
                                    },
                                    expression: "selectedType"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "actions" },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: {
                                          click: function($event) {
                                            _vm.showAssignImagesDialog = false
                                          }
                                        }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.updateImagesType($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("actions.save")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showAdditionalSubmissionDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showAdditionalSubmissionDialog,
                callback: function($$v) {
                  _vm.showAdditionalSubmissionDialog = $$v
                },
                expression: "showAdditionalSubmissionDialog"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.showAdditionalSubmissionDialog = false
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Aanvullende gegevens uitvragen")
                  ]),
                  _c("template", { slot: "content" }, [
                    _c("p", [
                      _vm._v(
                        " Je gaat aanvullende gegevens uitvragen voor de volgende " +
                          _vm._s(
                            _vm.additionalSubmissionDamages.length === 1
                              ? "schade"
                              : _vm.additionalSubmissionDamages.length +
                                  " schades"
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "ul",
                      { staticClass: "item__list item__list--no-style" },
                      [
                        _vm.applicantSubmission.additional_request &&
                        _vm.applicantSubmission.additional_request.length
                          ? _c(
                              "li",
                              { staticClass: "list__item" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs9: "" } }, [
                                      _vm._v("Generieke informatie")
                                    ]),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-right",
                                        attrs: { xs3: "" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "additional-submission-damage-icon additional-submission-damage-delete",
                                            on: {
                                              click:
                                                _vm.toggleGeneralRemarkField
                                            }
                                          },
                                          [_vm._v(" mdi-delete ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "py-0",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            rows: "2",
                                            "auto-grow": "",
                                            label:
                                              "Instructie voor de aanvrager"
                                          },
                                          model: {
                                            value:
                                              _vm.applicantSubmission
                                                .additional_request,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.applicantSubmission,
                                                "additional_request",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "applicantSubmission.additional_request"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.additionalSubmissionDamages, function(
                          additionalSubmissionDamage
                        ) {
                          return _c(
                            "li",
                            {
                              key: additionalSubmissionDamage.id,
                              staticClass: "list__item"
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs9: "" } }, [
                                    additionalSubmissionDamage.location ===
                                    "inside"
                                      ? _c("span", [
                                          _vm._v(
                                            " BINNEN - " +
                                              _vm._s(
                                                additionalSubmissionDamage
                                                  .answers["InsideRoomNumber"]
                                              ) +
                                              " " +
                                              _vm._s(
                                                additionalSubmissionDamage
                                                  .answers["InsideLocation"]
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " BUITEN - " +
                                              _vm._s(
                                                additionalSubmissionDamage
                                                  .answers["OutsideLocation"]
                                              ) +
                                              " "
                                          )
                                        ])
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-right",
                                      attrs: { xs3: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "additional-submission-damage-icon additional-submission-damage-delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleRemarkField(
                                                additionalSubmissionDamage
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" mdi-delete ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "py-0",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          rows: "2",
                                          "auto-grow": "",
                                          label: "Instructie voor de aanvrager"
                                        },
                                        model: {
                                          value:
                                            additionalSubmissionDamage.additional_request,
                                          callback: function($$v) {
                                            _vm.$set(
                                              additionalSubmissionDamage,
                                              "additional_request",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "additionalSubmissionDamage.additional_request"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-end": "", "align-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "fadedText link mr-3",
                                  on: {
                                    click: function($event) {
                                      _vm.showAdditionalSubmissionDialog = false
                                    }
                                  }
                                },
                                [_vm._v("Annuleren")]
                              ),
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.additionalSubmissionDamages.length &&
                                      !_vm.applicantSubmission
                                        .additional_request.length,
                                    color: "primary",
                                    small: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.createAdditionalRequest($event)
                                    }
                                  }
                                },
                                [_vm._v(" Uitvragen ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }